import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import "./Katalog.scss";

// Updated product data with specifications
const products = [
  {
    name: "Kamera HC-900 m 2G",
    description: "Suntek HC 900M, digitalna lovačka 2G kamera, sa funkcijom slanja fotografije putem GSM komunikacionih kanala. Idealna kamera za praćenje, nadzor i izvidjanje.",
    specifications: [
      "Senzor: 5 MP Color CMOS",
      "Memorija: 32GB Micro SD kartica",
      "Objektiv: F=3.1; FOV=120°",
      "IR Osvetljenje: Nevidljivo IR 940nm",
      "PIR Senzor: Ugao hvatanja 120°",
    ],
    price: "Cena: 12000 dinara",
    imageUrl: "./assets/HC9002G.jpg"
  },
  {
    name: "Kamera HC-940 Pro Li 4G",
    description: "Ono što izdvaja ovu kameru od mnogih je što uvek možete pristupiti snimku uživo sa mesta gde je postavljena!",
    specifications: [
      "Senzor: 8 MP",
      "Povezivanje: 4G LTE",
      "Kapacitet memorije: Do 64GB",
      "Ugao vidljivosti: 120°",
      "IR Osvetljenje: 850nm",
    ],
    price: "Cena: 19000 dinara",
    imageUrl: "./assets/KameraHC940.jpg"
  },
  {
    name: "Icar 122 T ogrlica za pracenje pasa",
    description: "Godinu dana garancije. Praćenje u realnom vremenu sa komandom preko mobilnog telefona.",
    specifications: [
      "Težina: 120g",
      "Baterija: 4000mAh",
      "Praćenje u realnom vremenu",
      "Kompatibilnost: Android i iOS",
      "Vodootporna: Da",
    ],
    price: "Cena: 12000 dinara",
    imageUrl: "./assets/Icar122M.jpeg"
  },
  {
    name: "Ls vision kamera",
    description: "Rotirajuca kamera 4G sa osjetljivim senzorom za pracenje divljaci, baterija 15000mAh, napajanje preko solarnog panela.",
    specifications: [
      "Senzor: 12 MP",
      "Kapacitet baterije: 15000mAh",
      "Povezivanje: 4G LTE",
      "Solarno napajanje",
      "Rotacija: 360°",
    ],
    price: "Cena: 23000 dinara",
    imageUrl: "./assets/Lsvisionkamera.jpeg"
  },
  {
    name: "Lampa Convoy C8",
    description: "Lampa sa osram pro diodom dometa 850 metara, uključuje bateriju.",
    specifications: [
      "Domet: 850 metara",
      "Izvor svetlosti: OSRAM",
      "Baterija: Uključena",
      "Materijal: Aluminijum",
      "Vodootporna: Da",
    ],
    price: "Cena: 7000 dinara",
    imageUrl: "./assets/LampaConvoyC8.jpeg"
  },
  {
    name: "Icar 122M",
    description: "GARANCIJA GODINU DANA. Praćenje preko aplikacije na mobilnom telefonu, poboljšana preciznost pozicioniranja.",
    specifications: [
      "Težina: 130g",
      "Baterija: 4000mAh",
      "Aplikacija: Android i iOS",
      "Preciznost: 5m",
      "Vodootporna: Da",
    ],
    price: "Cena: 11500 dinara",
    imageUrl: "./assets/Icar122T.jpeg"
  },
  {
    "name": "Janpet Teletakt",
    "description": "GARANCIJA GODINU DANA. Praćenje preko aplikacije na mobilnom telefonu, poboljšana preciznost pozicioniranja. Teletakt za dresuru pasa sa dometom do 1200m, potpuno vodootporan.",
    "specifications": [
      "Težina: 130g",
      "Baterija: 4000mAh",
      "Aplikacija: Android i iOS",
      "Preciznost: 5m",
      "Vodootporna: Da",
      "Domet: 1200m",
      "Funkcije: Zvuk, vibracija, struja (1-99)",
      "Mogućnost kupovine do tri ogrlice i jednog daljinskog"
    ],
    "price": "Cena: 13999 dinara",
    "imageUrl": "./assets/Teletakt.jpeg"
  },
];

const Katalog = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");

  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleDescription = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/'); // Correct route path
  };

  return (
    <div className="product-catalog">
      <h1>Naš Katalog Proizvoda</h1>
      <div className="product-list">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img 
              src={product.imageUrl} 
              alt={product.name} 
              className="product-image" 
              onClick={() => openModal(product.imageUrl)}
            />
            <div className="product-details">
              <h2 className="product-name">{product.name}</h2>
              <ul className="product-specifications">
                {product.specifications.map((spec, i) => (
                  <li key={i} className="specification-item">{spec}</li>
                ))}
              </ul>
              <p className="product-price">{product.price}</p>
              <button 
                onClick={() => toggleDescription(index)}
                className="toggle-description-button"
              >
                {expandedIndex === index ? "Sakrij Detalje" : "Prikaži Detalje"}
              </button>
              {expandedIndex === index && (
                <p className="product-description-expanded">
                  {product.description}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Modal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <button onClick={closeModal} className="modal-close-button">×</button>
        <img src={modalImageUrl} alt="Product" className="modal-image" />
      </Modal>

      {/* Button container */}
      <div className="button-container">
        <button onClick={handleButtonClick} className="view-catalog-button">Nazad na websajt</button>
      </div>
    </div>
  );
};

export default Katalog;