import React from "react";
import "./Footer.scss";
import { FaFacebookF, FaInstagram, FaTiktok, FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">

        </div>

        <div id="footer" className="footer-social">
          <a href="https://www.facebook.com/profile.php?id=100087977393740" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://www.instagram.com/lovacka_oprema22" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.tiktok.com/@lovacka_oprema22" target="_blank" rel="noopener noreferrer">
            <FaTiktok />
          </a>
          <a href="tel:+381644436186">
            <FaPhoneAlt />
          </a>
        </div>
      </div>

      <div className="footer-rights">
        <p>© 2024 LOVACKAOPREMA22. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;