import React from "react";
import "./About.scss";

const About = () => {
  return (
    <div id="about" className="about-container">
      <div className="about-text">
        <h1>O Nama</h1>
        <p>
          Strastveno pružamo lovačku opremu vrhunskog kvaliteta za sve entuzijaste. Naš asortiman obuhvata sve, od odeće i dodataka, do najpouzdanijih alata koji su vam potrebni za uspešan lov. Sa godinama iskustva, razumemo šta čini dobru opremu i trudimo se da svaki proizvod ispunjava naše visoke standarde.
        </p>
        <p>
          Bilo da ste iskusni lovac ili tek počinjete, nudimo stručne savete i širok izbor opreme koja će vam omogućiti sigurno i prijatno iskustvo u prirodi. Vaše putovanje u divljinu je onoliko dobro koliko je dobra oprema koju nosite, a mi se brinemo da imate najbolje od najboljeg.
        </p>
      </div>

      <div className="about-videos">
        <video 
          src="/assets/video1.mp4" 
          className="video video1" 
          autoPlay 
          loop 
          muted 
          playsInline 
        />
        <video 
          src="/assets/video2.mp4" 
          className="video video2" 
          autoPlay 
          loop 
          muted 
          playsInline 
        />
        <video 
          src="/assets/video3.mp4" 
          className="video video3" 
          autoPlay 
          loop 
          muted 
          playsInline 
        />
        <video 
          src="/assets/video4.mp4" 
          className="video video4" 
          autoPlay 
          loop 
          muted 
          playsInline 
        />
      </div>
    </div>
  );
};

export default About;
