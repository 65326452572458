/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Navbar from '../navbar/Navbar';
import About from "../aboutsec/About";
import Catalog from "../catalogsec/Catalog";
import "./Home.scss";

const Home = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 200); // Adjust scroll distance as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    <Navbar />
      <div className="hero">
        <video
          className="hero-video"
          autoPlay
          loop
          muted
          src="/assets/nav-video.mp4"  // Absolute path from the public directory
          type="video/mp4"
        />
        <div className="hero-content">
          <div className="hero-logo-container">
            <img
              src="/logo512.png"
              alt="Logo"
              className="hero-logo"
            />
          </div>
          <div className="hero-button-container">
          </div>
        </div>
      </div>
      <About /> {/* Include the About section */}
      <Catalog /> {/* Include the About section */}
      </>
  );
};

export default Home;
